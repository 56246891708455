import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"

export default class extends Controller {
  static targets = ["existingInfectionReports", "infectionReportLink" ]

  connect() {
    $(this.existingInfectionReportsTarget).on("select2:select", function () {
      let event = new Event("change", { bubbles: true })
      this.dispatchEvent(event)
    })

  }

  reportChange(event) {
    this.infectionReportLinkTarget.href = Routes.admin_infection_report_path(event.target.value)
  }

  enableExistingInfectionReports(_event) {
    this.existingInfectionReportsTarget.disabled = false
  }

  disableExistingInfectionReports(_event) {
    this.existingInfectionReportsTarget.disabled = true
  }

  next(event) {
    event.preventDefault()

    const params = new FormData(event.target)
    const signal_ids = params.get("signal_ids")
    const report_type = params.get("report_type")
    const infection_report_id = params.get("existing_infection_reports")

    const new_or_edit_modal =
      report_type == "new" ?
        Routes.new_admin_infection_report_path({ signal_ids: signal_ids, create_new_over_existing: true }) : Routes.edit_signals_admin_infection_report_path(infection_report_id, { signal_ids: signal_ids })

    this.dispatch("openModal", {
      detail: {
        options: { url: new_or_edit_modal },
        showLoading: false,
        modalClass: "xl"
      },
      prefix: false
    })

    return false
  }
}
