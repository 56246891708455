import { Controller } from "@hotwired/stimulus"
import EventTypes from "event_types"

export default class extends Controller {
  static targets = ["form", "quickSelect", "quickSelectContainer", "submit", "textArea", "type"]

  connect() {
    $("body").on("shown.bs.modal", ".modal", this.showFieldsForType.bind(this))

    this.attachValidateFormListener()
    this.showFieldsForType()
  }

  handleRequestSuccess(e) {
    $(this.formTarget).find("input").val("")
    $(this.formTarget).find("textarea").val("")
    const element = e.target
    $(this.element).trigger(EventTypes.REMEDIATION_ADDED, element.dataset.subtype)
  }

  handleRequestError(event) {
    let errorMsg = event.detail[0].error
    console.log(errorMsg)
    alert(errorMsg)
  }

  typeChanged() {
    this.showFieldsForType()
  }

  attachValidateFormListener(){
    if(!this.hasTextAreaTarget)
      return

    $(this.textAreaTarget).on("input", (e) =>{
      if(this.textAreaTarget.value.length > 0)
        this.submitTarget.disabled = false
      else
        this.submitTarget.disabled = true
    })
  }

  showFieldsForType() {
    if(!this.hasTypeTarget)
      return

    $("#add-assisted-remediation-modal .parameters").hide()

    let value = this.type
    if (value === "") return

    $("#" + value).show()

    $("#add-assisted-remediation-modal .parameters input").prop("disabled", true)
    $("#" + value + " input").prop("disabled", false)

    if (this.isProcessRemediation) {
      this.quickSelectChanged()
      this.quickSelectContainerTarget.classList.remove("hidden")
    } else {
      this.quickSelectContainerTarget.classList.add("hidden")
    }

    if (this.isRebootRemediation) {
      $("#remediation_reboot_message").val(this.data.get("defaultRebootMessage"))
    }
  }

  quickSelectChanged() {
    let array = this.quickSelectTarget.value.split(" - ")

    if (array.length > 1) {
      $("#remediation_pid").val(array[0])
      $("#Remediations-Process #remediation_path").val(array[1])
    }
  }

  removeRemediationFromQuickSelect() {
    let pid = $("#remediation_pid").val()
    let path = $("#Remediations-Process #remediation_path").val()
    let submission = `${pid} - ${path}`

    for (let i = 0; i < this.quickSelectTarget.length; i++) {
      if (this.quickSelectTarget.options[i].value === submission) {
        this.quickSelectTarget.remove(i)
      }
    }
  }

  get type() {
    return this.typeTarget.value.replace(/::/g, "-")
  }

  get isRebootRemediation() {
    return this.type === "Remediations-Reboot"
  }

  get isProcessRemediation() {
    return this.type === "Remediations-Process"
  }
}
