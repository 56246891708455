import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "confirmationModal",
    "confirmationModalTitle",
    "confirmationModalBody",
    "confirmButton",
  ]

  connect() {
    this.subscriptions = []
  }

  onSetSelectedPlan(e) {
    let { product, price } = e.detail
    this.removeProductFromSubscriptions(product)
    if (price) this.subscriptions.push(e.detail)
  }

  onProductRemoved(e) {
    let { product } = e.detail
    this.removeProductFromSubscriptions(product)
  }

  removeProductFromSubscriptions(product) {
    this.subscriptions = this.subscriptions.filter((sub) => sub.product !== product)
  }

  showConfirmationModal(){
    let cartIsEmpty = this.subscriptions.length === 0

    if (cartIsEmpty) {
      let actionText = this.isUpgrade ? "upgrade your subscription" : "start a subscription"
      this.confirmationModalTitleTarget.textContent = "Invalid Subscription Minimum"
      this.confirmationModalBodyTarget.textContent = `In order to ${actionText}, please change the minimum for the product you would like to add and try again.`
      this.confirmButtonTarget.classList.add("hidden")
    }
    else {
      this.confirmationModalTitleTarget.textContent = this.isUpgrade ? "Confirm Upgrade Subscription" : "Confirm Save and Start Subscriptions"
      this.confirmationModalBodyTarget.textContent = `Are you sure you would like to ${this.isUpgrade ? "upgrade" : "save and start"} ${this.subscriptions.length > 1 ? `these ${this.subscriptions.length}` : "this"} subscription${this.subscriptions.length > 1 ? "s" : ""}?`
      this.confirmButtonTarget.classList.remove("hidden")
    }
    $("#confirmationModal").modal()
  }
}
