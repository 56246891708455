import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggle", "content", "input"]

  showInput() {
    this.contentTargets.forEach(element => element.classList.add("hide"))
    this.inputTargets.forEach(element => {
      element.classList.remove("hide")
      element.querySelector("[autofocus]")?.focus()
    })
    this.disableSendButton()
  }

  showContent() {
    this.inputTargets.forEach(element => element.classList.add("hide"))
    this.contentTargets.forEach(element => element.classList.remove("hide"))
    this.enableSendButton()
  }

  disableSendButton() {
    this.enabledSendButton.classList.add("hide")
    this.disabledSendButton.classList.remove("hide")
  }

  enableSendButton() {
    this.disabledSendButton.classList.add("hide")
    this.enabledSendButton.classList.remove("hide")
  }

  get enabledSendButton() {
    return document.getElementById("infection-report-send-btn")
  }

  get disabledSendButton() {
    return document.getElementById("infection-report-disabled-send-btn")
  }
}
