import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "plan",
    "minimum",
    "months",
    "basePrice",
    "discountPercentage",
    "salePrice",
    "rampTemplate",
    "rampContainer",
    "targetPriceAfterUpliftContainer",
    "targetPriceAfterUplift",
    "retainDiscountContainer",
    "retainDiscountCheckbox",
    "targetPriceAfterPromoContainer",
    "targetPriceAfterPromo",
    "overagePrice"
  ]

  connect() {
    if(this.data.get("new") === "New"){
      this.setFormState()
    }
  }

  planChanged() {
    this.setFormState()
  }

  setFormState() {
    this.minimumTarget.value = this.selectedPlanMinimum()
    this.basePriceTarget.value = (this.selectedPlanAmount() / 100).toFixed(2)
    this.salePriceTarget.value = (this.selectedPlanAmount() / 100).toFixed(2)
    if (this.isPaygPlan()) {
      this.monthsTarget.value = 0
      this.monthsTarget.setAttribute("disabled", "")
    } else {
      this.monthsTarget.value = 12
      this.monthsTarget.removeAttribute("disabled")
    }

    if (this.isPromoPlan() && this.promoWillExpire == "true") {
      this.showTargetPriceAfterPromo()
    } else {
      this.hideTargetPriceAfterPromo()
    }

    this.populateOveragePrice()
    this.salePriceInput()
  }

  selectedPlanAmount() {
    return parseFloat(this.selectedPlanOptions().getAttribute("data-plan-amount"))
  }

  selectedPlanPromo() {
    return this.selectedPlanOptions().getAttribute("data-plan-promo") == "true"
  }

  selectedPlanOptions() {
    return this.planTarget.options[this.planTarget.selectedIndex]
  }

  salePriceChanged() {
    if (this.salePrice > this.basePrice || this.salePrice < 0) {
      alert("Sale price must be between 0 and " + this.basePrice)
      this.discountPercentageTarget.value = 0
      this.salePriceTarget.value = (this.selectedPlanAmount() / 100).toFixed(2)
      return false
    }
    this.updateDiscountPercentage()
  }

  updateDiscountPercentage() {
    const newDiscount =  (1 - (this.salePrice / this.basePrice)) * 100
    this.discountPercentageTarget.value = newDiscount.toFixed(2)
  }

  cloneRampTemplate() {
    return this.rampTemplateTarget.querySelector(".ramp-field").cloneNode(true)
  }

  selectedPlanMinimum() {
    return this.selectedPlanOptions().getAttribute("data-plan-minimum")
  }

  isPaygPlan() {
    return /pay as you go/i.test(this.selectedPlanText()) || /startup plan/i.test(this.selectedPlanText())
  }

  isPromoPlan() {
    return this.selectedPlanPromo()
  }

  selectedPlanText() {
    return this.selectedPlanOptions().text
  }

  addRamp() {
    if (this.rampMonth >= 12) {
      alert("You can't have more than 12 months of ramp!")
      return false
    }

    this.rampMonth += 1
    let el = this.cloneRampTemplate()
    this.updateElementText(el, this.rampMonth)
    this.rampContainerTarget.appendChild(el)
  }

  removeRamp(ev) {
    let el = ev.target.closest(".ramp-field")
    el.remove()
    this.rampMonth -= 1
    this.rampContainerTarget.querySelectorAll(".ramp-field").forEach((el, idx) =>
      this.updateElementText(el, idx + 1)
    )
  }

  updateElementText(el, text) {
    return el.querySelector("label").innerText = `Month ${text}`
  }

  get rampMonth() {
    return parseInt(this.data.get("rampMonth"))
  }

  get promoWillExpire() {
    return this.data.get("promoWillExpire")
  }

  set rampMonth(numMonths) {
    this.data.set("rampMonth", numMonths)
  }

  salePriceInput() {
    if (this.isEDRProduct && !this.isFinalPricingPhase && this.isCustomSalePrice()) {
      this.showTargetPriceAfterUplift()
    } else {
      this.hideTargetPriceAfterUplift()
    }
    if (this.isCustomSalePrice()) {
      this.showRetainDiscountContainer()
    } else {
      this.hideRetainDiscountContainer()
    }
    this.updateDiscountPercentage()
  }

  populateOveragePrice() {
    if (!this.hasOveragePriceTarget) {
      return
    }

    // # TODO: LOGS BETA - Uncapped Overages, add the next couple lines
    // const price = this.selectedPlanOptions().getAttribute("data-plan-overage-amount")
    // this.overagePriceTarget.value = price
  }

  zeroOutOveragePrice() {
    if (!this.hasOveragePriceTarget) {
      return
    }

    this.overagePriceTarget.value = 0
  }

  isCustomSalePrice() {
    return !isNaN(this.salePrice) && this.salePrice < this.basePrice
  }

  showTargetPriceAfterUplift() {
    this.targetPriceAfterUpliftContainerTarget.hidden = false
    this.targetPriceAfterUpliftTarget.removeAttribute("disabled")
  }

  hideTargetPriceAfterUplift() {
    this.targetPriceAfterUpliftContainerTarget.hidden = true
    this.targetPriceAfterUpliftTarget.disabled = true
  }

  showTargetPriceAfterPromo() {
    this.targetPriceAfterPromoContainerTarget.hidden = false
    this.targetPriceAfterPromoTarget.removeAttribute("disabled")
  }

  hideTargetPriceAfterPromo() {
    this.targetPriceAfterPromoContainerTarget.hidden = true
    this.targetPriceAfterPromoTarget.disabled = true
  }

  get basePrice() {
    return parseFloat(this.basePriceTarget.value)
  }

  get salePrice() {
    return parseFloat(this.salePriceTarget.value)
  }

  get isFinalPricingPhase() {
    return huntressData.is_final_pricing_phase
  }

  get isEDRProduct() {
    return this.product == "edr"
  }

  get product() {
    return this.data.get("product")
  }

  showRetainDiscountContainer() {
    this.retainDiscountContainerTarget.hidden = false
    this.retainDiscountCheckboxTarget.removeAttribute("disabled")
  }

  hideRetainDiscountContainer() {
    this.retainDiscountContainerTarget.hidden = true
    this.retainDiscountCheckboxTarget.checked = false
    this.retainDiscountCheckboxTarget.disabled = true
  }

  keydown(event) {
    // Force the user to click the button to prevent accidental submission by pressing the 'enter' key.
    if (event.keyCode == 13){
      event.preventDefault()
      return false
    }
  }
}
