import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // set cursor to top-left position of text area and make sure it is visible when editing report
    this.setupCustomFocusAndScrollAdjustment()
  }

  setupCustomFocusAndScrollAdjustment() {
    const observer = new MutationObserver((mutationsList) => {
      for(const mutation of mutationsList) {
        if (mutation.target.id == "report-body-text-area"){
          this.setCursorAndScrollbars(mutation.target)
          break
        }
      }
    })
    observer.observe(this.element, { attributes: true, childList: true, subtree: true })
  }

  setCursorAndScrollbars(textArea) {
    textArea.setSelectionRange(0,0)
    textArea.scrollTop = 0
    this.adjustMainWindowScrollPosition()
  }

  adjustMainWindowScrollPosition() {
    document.documentElement.scrollTop = this.topPositionOffset(this.element, $("#main-navbar-collapse").height())
  }

  topPositionOffset(el, offset) {
    return (el.getBoundingClientRect().top + window.scrollY) - offset
  }
}
