import { Controller } from "@hotwired/stimulus"
import { parseNestedFormData, flashHighlightId } from "huntressHelpers"
import * as Routes from "routes"

export default class extends Controller {
  static targets = [
    "expiresAtDateUtc",
    "expiresAtFields",
    "form",
    "messages",
    "neverExpires",
    "overrideSubmitForm",
    "previewRule",
    "ruleConfirmationModalHeader",
    "submitForm",
    "usageLocationMessage",
  ]

  connect() {
    if(this.hasSubmitFormTarget){
      $(this.submitFormTarget).on("click", () => {
        this.submitForm()
      })
    }
    if(this.hasPreviewRuleTarget) {
      $(this.previewRuleTarget).on("click", () => {
        this.validateRule()
      })
    }
    if(this.hasOverrideSubmitFormTarget){
      $(this.overrideSubmitFormTarget).on("click", () => {
        this.submitForm(true)
      })
    }
  }

  parseFormData(){
    const formData = new FormData($("#attribute-rule-form").get(0))
    const formObject = parseNestedFormData(formData)
    return formObject
  }

  async validateRule(){
    const formObject = this.parseFormData()
    const url = `${Routes.validate_account_managed_identity_attribute_rules_path()}?${new URLSearchParams(formObject.managed_identity_attribute_rule).toString()}`
    const response = await fetch(url)

    if(response.status != 200)
      return this.displayMessage("error", "Unable to preview rule")

    const jsonResponse = await response.json()

    if(jsonResponse.status)
      return this.displayMessage("success", "No conflicts detected")

    if(jsonResponse.parent)
      return this.displayMessage("warning", `This rule will override another rule in the ${jsonResponse.parent.ruleable_type} scope. Click 'Add Rule' to confirm.`)

    if(jsonResponse.error)
      return this.displayMessage("error", jsonResponse.error)
  }

  async submitForm(override=false){
    // FIXME: Remove the `this.hasNeverExpiresTarget &&` part of this `if` condition
    // when the `itdr_attribute_rule_expires_at` feature flag has been removed
    if (this.hasNeverExpiresTarget && !this.neverExpiresTarget.checked && !this.expiresAtDateUtcTarget.value) {
      return this.displayMessage("error", 'Set an expiration date, or select "Never Expires".')
    }

    let formObject = this.parseFormData()
    if(override){
      formObject["validated"] = true
    }
    const response = await fetch($("#attribute-rule-form").get(0).action, {
      method: formObject?._method?.toUpperCase() || "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formObject),
    })

    if(response.status != 200){
      try {
        const jsonResponse = await response.json()
        return this.displayMessage("error", jsonResponse.error)
      }
      catch (_) {
        // Reload if unable to parse error, there should be a flash message
        window.location.reload()
      }
    }

    const jsonResponse = await response.json()
    if(jsonResponse.identity_count){
      const count = jsonResponse.identity_count
      const country = jsonResponse.country
      return this.showIdentityCountModal(country, count)
    }
    window.location.hash = jsonResponse.redirect_anchor
    window.location.reload()
  }

  determinationExpected(){
    // FIXME: Remove the wrapping `if` condition
    // when the `itdr_attribute_rule_expires_at` feature flag has been removed
    if (this.hasExpiresAtDateUtcTarget) {
      this.expiresAtFieldsTarget.classList.remove("disabled")
      this.neverExpiresTarget.disabled = false
    }
    this.usageLocationMessageTarget.classList.add("hidden")
  }

  determinationUnauthorized(){
    // FIXME: Remove the wrapping `if` condition
    // when the `itdr_attribute_rule_expires_at` feature flag has been removed
    if (this.hasExpiresAtDateUtcTarget) {
      this.expiresAtFieldsTarget.classList.add("disabled")
      this.expiresAtDateUtcTarget.value = null
      this.expiresAtDateUtcTarget.disabled = true
      this.neverExpiresTarget.checked = true
      this.neverExpiresTarget.disabled = true
    }
    this.usageLocationMessageTarget.classList.remove("hidden")
  }

  toggleNeverExpires(event){
    if (event.target.checked) {
      this.expiresAtDateUtcTarget.value = null
      this.expiresAtDateUtcTarget.disabled = true
    } else {
      this.expiresAtDateUtcTarget.disabled = false
    }
  }

  showIdentityCountModal(country, count){
    $("#title-country").text(country)
    $("#country").text(country)
    $("#identity-count").text(count)

    $(".ajax-modal").modal("hide")
    $("#rule-confirmation-modal").modal("show")
  }

  displayMessage(level, message){
    const messagesEl = $(this.messagesTarget)
    const messageEl = messagesEl.find(".message")
    let color = ""
    messagesEl.find(".fa").each((_, e) => {
      $(e).hide()
    })
    switch (level) {
    case "success":
      color = "#18BC9C"
      messagesEl.find(".success-icon").show()
      break
    case "warning":
      color = "#3498DB"
      messagesEl.find(".warning-icon").show()
      break
    case "error":
      color = "#E74C3C"
      messagesEl.find(".error-icon").show()
      break
    }

    messageEl.text(message)
    messageEl.css("color", color)
    messagesEl.show()
    flashHighlightId(messagesEl, 500, color)
  }
}
