import { Controller } from "@hotwired/stimulus"
import * as Routes from "routes"
import EventTypes from "event_types"

export default class extends Controller {
  static targets = ["remediationCount"]

  static values = {
    invalidIps: Object
  }

  connect() {
    this.#clearPreviousBootstrapModals()
    this.#registerAssistedRemediationSortable()

    // Legacy Connects
    this.registerRemediationHandlers()
    this.populateSelectableAnalystsInModal()
    this.overrideModalLinkClickAction()
  }

  #clearPreviousBootstrapModals() {
    document.querySelectorAll(".modal-backdrop").forEach(element => element.remove())
    document.body.classList.remove("modal-open")
  }

  #registerAssistedRemediationSortable() {
    $("#assisted-remediations-list tbody").sortable({
      handle: ".fa-bars",
      update: function() {
        $.ajax({
          url: $("#assisted-remediations-list").data("url"),
          type: "PATCH",
          data: $(this).sortable("serialize"),
          headers: { "X-CSRF-Token": $("meta[name=csrf-token]").attr("content") },
        })
      }
    })
  }

  // Legacy JS
  remediationsTableController(type) {
    let selector = ""
    if(type == "assisted")
      selector = "#assisted-remediations-list > div"
    else
      selector = "#manual-remediations-list > div"

    return this.application.getControllerForElementAndIdentifier(
      this.element.querySelector(selector), "components--table"
    )
  }

  addRemediationRow(_, type) {
    if(type == "assisted")
      $("#add-assisted-remediation-modal").modal("hide")
    else{
      $("#add-manual-remediation-modal").modal("hide")
      $("#ajax-edit-modal").modal("hide")
    }

    const badge = $(this.remediationCountTarget)
    badge.html(parseInt(badge.html()) + 1)

    this.remediationsTableController(type).reload()
  }

  remediationRemoved(event) {
    const badge = $(this.remediationCountTarget)
    badge.html(parseInt(badge.html()) - 1)

    let type = "assisted"
    if($(event.currentTarget).parents("#manual-remediations-list").length > 0)
      type = "manual"

    this.remediationsTableController(type).reload()
  }

  remediationRemovalError() {
    alert("Failed to delete remediation. Contact engineering if this continues.")
  }

  registerRemediationHandlers() {
    $(this.element).on(EventTypes.REMEDIATION_ADDED, this.addRemediationRow.bind(this))

    $("#assisted-remediations-list")
      .on("ajax:success", "tr", this.remediationRemoved.bind(this))
      .on("ajax:error", "tr", this.remediationRemovalError.bind(this))

    $("#manual-remediations-list")
      .on("ajax:success", "tr", this.remediationRemoved.bind(this))
      .on("ajax:error", "tr", this.remediationRemovalError.bind(this))
  }

  populateSelectableAnalystsInModal() {
    $("#infection_report_assigned_to_id")
      .select2({
        theme: "bootstrap",
        ajax: {
          url: Routes.select2_admin_users_path("json", { roles: ["admin", "analyst", "analyst_admin", "customer_success"] }),
          dataType: "json",
          delay: 250
        },
        width: "100%"
      })
  }

  overrideModalLinkClickAction() {
    $(document).on("click", ".modal-link", function(e) {
      e.preventDefault()
      $.ajax({
        url: $(this).attr("href"),
        dataType: "json",
        success: function(data) {
          $("#details-modal .modal-dialog").html(data.content)
          $("#details-modal").modal("show")
        },
        error: function(err) {
          console.log(err)
        }
      })
    })
  }
}
