import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  preventUnload = false

  connect() {
    this.beforeUnloadHandler = this.beforeUnload.bind(this)
    window.addEventListener("beforeunload", this.beforeUnloadHandler)
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.beforeUnloadHandler)
  }

  allowUnload() {
    this.preventUnload = false
  }

  disallowUnload() {
    this.preventUnload = true
  }

  beforeUnload(event) {
    if (this.preventUnload === true) {
      event.preventDefault()
    }
  }
}
